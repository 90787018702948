.statistic-item-container {
  padding-bottom: 10px;
  padding-top: 10px;
  font-weight: bold;
}

.statistic-right-container {
  display: flex;
  justify-content: right;
  align-items: center;
  padding:0.5rem 3.5rem;
  font-weight: bold;
}

.social-link-icon{
  padding: 3px;
}
.social-link-icon:hover {
  cursor: pointer;
  border-radius: 50%;
  svg {
    color: white!important;
  }
  /* border-style: dashed; */
}

.discord-hover-color:hover {
  background-color: #5562EA;
}

.twitter-hover-color:hover {
  background-color: rgb(66, 66, 66);
}

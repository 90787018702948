.register {
  display: flex;
  justify-content: center;
}

.register .container {
  max-width: 400px;
  flex: 1;
}

.register .submit {
  background-color: gray;
  color: white;
}

.form-group {
  .form-control{
    font-size: 12px!important;
  }
  .form-control::placeholder{
    font-size: 12px!important;
  }
}

.profile .profile-info {
  display: flex;
  padding-bottom: 20px;
}

.profile .avatar {
  width: 220px;
  height: 220px;
  align-self: center;
  margin-right: 20px;
  object-fit: cover;
}

.profile .label,
.profile label {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}

.profile .info {
  font-size: 18px;
}

.profile .info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile .form {
  margin-bottom: 30px;
}

.profile-avatar {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
}

.profile-avatar label {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #33333300;
  cursor: pointer;
  transition: all 0.3s;
}

.profile-avatar label:hover {
  background-color: #33333377;
}

.profile-avatar .avatar-input {
  position: absolute;
  opacity: 0;
  z-index: -9999;
}

.profile-avatar .avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

img:not([src]) {
  font-size: 0;
  position: relative;
}
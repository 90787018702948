$search-box-height: 480px;
$search-box-width: 480px;

.search-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  background-color: #222531;
  color: #646b80;
  border-color: transparent;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  font-size: 18;
}

.slashButton {
  background-color: #646b80;
  padding: 1px 7px;
  border-radius: 4px;
  color: white;
  font-size: 18;
}

.search-modal-border {
  width: $search-box-width;
  padding: 6px 10px;
  border-radius: 8px;
  background-color: #222531;
  @media screen and (max-width: 992px) {
    width: 100%;
    height: 100%;
  }
}
.search-modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  border-radius: 8px;
  background-color: #171924;
  @media screen and (max-width: 992px) {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.project-search-input {
  background-color: #171924 !important;
  border: none !important;
  padding: 3px 8px 3px 3px !important;
  width: 100%;
  color: white;
  @media screen and (max-width: 992px) {
    height: 60px;
    font-size: 20px;
  }
}
.search-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 4px 8px;
  border-radius: 8px;
  height: 36px;
  @media screen and (max-width: 992px) {
    height: 70px;
  }
}
.search-result-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 24px 12px;
  height: 36px;
  border-radius: 8px;
  font-size: 14px;
}

.search-result-row:hover {
  background-color: #222531;
}

.hide-button {
  cursor: pointer;
}

.project-active {
  background-color: #222531;
}

.projects {
  height: $search-box-height;
  margin: 0%;
  width: 100%;
  padding: 46px 10px 1px 8px;
  &.more-padding-top{
    padding-top: 88px;
  }
  @media screen and (max-width: 992px) {
    height: 100%;
    min-height: 100%;
  }
}

.no-projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: $search-box-height !important;
  font-size: 14px;
  @media screen and (max-width: 992px) {
    height: 100% !important;
    min-height: 100%;
  }
}
.search-result-modal {
  max-height: $search-box-height;
  overflow-y: auto;
  width: 100%;
  @media screen and (max-width: 992px) {
    height: 100%;
    min-height: 100%;
  }
}

.category-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  position: absolute;
  top: 42px;
  background: #171924;
  padding: 6px 0px;
  width: calc(100% - 38px);
  z-index: 1;
}

.category-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 120px;
  min-width: 64px;
  border: none;
  height: 32px;
  padding: 4px 8px;
  background-color: #323546;
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.2s ease-in-out;

  &.selected,
  &.selected:hover {
    background-color: #1e274f;
    color: #3861fb;
  }

  &:hover {
    background-color: #ffffff29;
    color: #ffffff;
  }

  @media screen and (max-width: 992px) {
  }
}

.category-title {
  padding: 8px 12px;
  margin-bottom: 0;
  font-size: 13px;
  color: #858ca2;
}

.see-all-results {
  padding: 8px 12px;
  margin-bottom: 0;
  font-size: 12px;
  color: #6188ff;
  cursor: pointer;
}

.category-back-wrapper {
  position: absolute;
  width: calc(100% - 38px);
  background: #171924;
  top: 86px;
}

.category-back {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 89px;
  padding: 8px 8px;
  cursor: pointer;
  p {
    font-size: 14px;
    margin-left: 8px;
  }
}

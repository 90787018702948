.cus-container {
  width: 100%;
  max-width: 1504px;
  /* padding-right: 16px;
  padding-left: 16px; */
  margin-right: auto;
  margin-left: auto;
}

.icon-btn {
  height: 41px;
  border-radius: 10px;
  padding: 8px 8px;
  border: 1px solid #323546;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.link-btn {
  /* height: 41px; */
  border-radius: 10px;
  padding: 5px 8px;
  border: 1px solid #323546;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #323546;
  transition: all 0.2s ease;
  cursor: pointer;
}

.link-btn:hover {
  background-color: #858ca2;
}

.cus-badge {
  padding: 2px 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: bold;
  width: fit-content;
  height: fit-content;
  border-radius: 3px;
}

.border-top {
  border-top: 1px solid #323546 !important;
}
.border-right {
  border-right: 1px solid #323546 !important;
}
.border-left {
  border-left: 1px solid #323546 !important;
}
.border-bottom {
  border-bottom: 1px solid #323546 !important;
}

.chart-switch-btn {
  background-color: transparent;
  font-weight: bold;
  transition: all 0.2s ease;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #546077;
}

.chart-switch-btn.selected {
  background-color: #323546;
}

.project-chart-switch-con {
  background-color: #222531;
  padding: 3px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  border: 1px solid #323546;
}

.chart-term-switch-btn {
  background-color: transparent;
  font-weight: bold;
  transition: all 0.2s ease;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #97a7bb;
}

.chart-term-switch-btn:hover {
  background-color: #171924;
}

.chart-term-switch-btn.selected {
  background-color: #171924;
}

.project-chart-term-switch-con {
  background-color: #323546;
  padding: 3px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}

.title-text {
  font-size: 24px;
  color: white;
  font-weight: bold;
}

.sub-title-text {
  font-size: 18px;
  color: white;
  font-weight: bold;
}

.sub-title-text span.post-date,
.poster-name {
  font-size: 12px;
  color: #858ca2;
  font-weight: unset;
}

.infulencer-type-switch-btn {
  background-color: transparent;
  font-weight: bold;
  transition: all 0.2s ease;
  width: fit-content;
  padding: 10px 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #a1a7bb;
  flex: 1;
  text-align: center;
}

.infulencer-type-switch-btn:hover {
  color: white;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.3);
}

.infulencer-type-switch-btn.selected {
  background-color: #323546;
  color: white;
}

.infulencer-type-switch-con {
  background-color: #222531;
  padding: 3px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border: 1px solid #323546;
  flex: 2;
}

.top-infulencer-con {
  height: 100%;
  max-height: calc(100vh - 100px);
  background-color: #222531;
  border-radius: 20px;
  padding: 10px 0px;
}

.top-infulencer-top-con {
  padding: 0px 10px;
  position: sticky !important;
  top: -1px;
  background: #212531;
  z-index: 1;
}

.top-infulencer-bottom-con{
  height: calc(100% - 123px);
  overflow-y: auto;
  overflow-x: hidden;
}

.comment-con {
  padding: 10px 20px;
  cursor: pointer;
}

.comment-con:hover {
  background-color: #323546;
  border-radius: 20px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16), 0 4px 6px rgba(0, 0, 0, 0.45);
}

.comment-count-con {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-top: 1px solid #323546;
  border-bottom: 1px solid #323546;
  padding: 10px 0px;
}

@media (min-width: 576px) {
  .border-sm-top {
    border-top: 1px solid #232531 !important;
  }
  .border-sm-right {
    border-right: 1px solid #232531 !important;
  }
  .border-sm-bottom {
    border-bottom: 1px solid #232531 !important;
  }
  .border-sm-left {
    border-left: 1px solid #232531 !important;
  }
  .border-sm-top-0 {
    border-top: 0 !important;
  }
  .border-sm-right-0 {
    border-right: 0 !important;
  }
  .border-sm-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-sm-left-0 {
    border-left: 0 !important;
  }
  .border-sm-x {
    border-left: 1px solid #232531 !important;
    border-right: 1px solid #232531 !important;
  }
  .border-sm-y {
    border-top: 1px solid #232531 !important;
    border-bottom: 1px solid #232531 !important;
  }
  .border-sm {
    border-top: 1px solid #232531 !important;
    border-bottom: 1px solid #232531 !important;
    border-left: 1px solid #232531 !important;
    border-right: 1px solid #232531 !important;
  }
  .border-sm-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

@media (min-width: 768px) {
  .border-md-top {
    border-top: 1px solid #232531 !important;
  }
  .border-md-right {
    border-right: 1px solid #232531 !important;
  }
  .border-md-bottom {
    border-bottom: 1px solid #232531 !important;
  }
  .border-md-left {
    border-left: 1px solid #232531 !important;
  }
  .border-md-top-0 {
    border-top: 0 !important;
  }
  .border-md-right-0 {
    border-right: 0 !important;
  }
  .border-md-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-md-left-0 {
    border-left: 0 !important;
  }
  .border-md-x {
    border-left: 1px solid #232531 !important;
    border-right: 1px solid #232531 !important;
  }
  .border-md-y {
    border-top: 1px solid #232531 !important;
    border-bottom: 1px solid #232531 !important;
  }
  .border-md {
    border-top: 1px solid #232531 !important;
    border-bottom: 1px solid #232531 !important;
    border-left: 1px solid #232531 !important;
    border-right: 1px solid #232531 !important;
  }
  .border-md-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

@media (min-width: 992px) {
  .border-lg-top {
    border-top: 1px solid #232531 !important;
  }
  .border-lg-right {
    border-right: 1px solid #232531 !important;
  }
  .border-lg-bottom {
    border-bottom: 1px solid #232531 !important;
  }
  .border-lg-left {
    border-left: 1px solid #232531 !important;
  }
  .border-lg-top-0 {
    border-top: 0 !important;
  }
  .border-lg-right-0 {
    border-right: 0 !important;
  }
  .border-lg-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-lg-left-0 {
    border-left: 0 !important;
  }
  .border-lg-x {
    border-left: 1px solid #232531 !important;
    border-right: 1px solid #232531 !important;
  }
  .border-lg-y {
    border-top: 1px solid #232531 !important;
    border-bottom: 1px solid #232531 !important;
  }
  .border-lg {
    border-top: 1px solid #232531 !important;
    border-bottom: 1px solid #232531 !important;
    border-left: 1px solid #232531 !important;
    border-right: 1px solid #232531 !important;
  }
  .border-lg-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .border-xl-top {
    border-top: 1px solid #232531 !important;
  }
  .border-xl-right {
    border-right: 1px solid #232531 !important;
  }
  .border-xl-bottom {
    border-bottom: 1px solid #232531 !important;
  }
  .border-xl-left {
    border-left: 1px solid #232531 !important;
  }
  .border-xl-top-0 {
    border-top: 0 !important;
  }
  .border-xl-right-0 {
    border-right: 0 !important;
  }
  .border-xl-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-xl-left-0 {
    border-left: 0 !important;
  }
  .border-xl-x {
    border-left: 1px solid #232531 !important;
    border-right: 1px solid #232531 !important;
  }
  .border-xl-y {
    border-top: 1px solid #232531 !important;
    border-bottom: 1px solid #232531 !important;
  }
  .border-xl {
    border-top: 1px solid #232531 !important;
    border-bottom: 1px solid #232531 !important;
    border-left: 1px solid #232531 !important;
    border-right: 1px solid #232531 !important;
  }
  .border-xl-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

.home-btn {
  min-height: 48px;
  color: #04101b;
  background-color: transparent;
  background-image: url("/public/images/btn-green-bg.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #00ffa7;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  padding: 12px 46px;
  font-size: 16px;
  text-decoration: none;
  transition: color 0.3s, background-position 0.3s, background-color 0.3s;
  display: flex;
}

.large {
  background-color: transparent;
  padding: 17px 42px;
  font-size: 18px;
}

.home-btn.large:hover {
  background-color: transparent;
}
.home-btn.large {
  background-color: transparent;
  padding: 17px 42px;
  font-size: 18px;
}
.home-btn:hover {
  color: #ffffff;
  background-color: transparent;
  background-position: 50% 100px;
}
.home-btn.outline {
  color: #00ffa7;
  background-color: transparent;
  background-image: url("/public/images/btn-white-bg.jpg");
  background-position: 0 100px;
  border-color: #00ffa7;
  transition: border-color 0.3s, color 0.3s, background-position 0.3s,
    background-color 0.3s;
}
.home-btn.outline:hover {
  border-color: #ffffff;
  color: #04101b;
  background-position: 0 0;
}

/* Animation Mixins */
@keyframes dropdownAnimation {
    from {
        opacity: 0;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
    }

    to {
        opacity: 1;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
    }
}

.dropdownAnimation {
    animation-name: dropdownAnimation;
    @include animation-duration($action-transition-duration);
    @include animation-fill-mode(both);
}

@mixin transition($settings) {
    -webkit-transition: $settings;
    -moz-transition: $settings;
    -ms-transition: $settings;
    -o-transition: $settings;
    transition: $settings;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fadeOut {
    animation-name: fadeOut;
}

.infinite-spin {
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    animation-name: spin;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInUp {
    animation-name: fadeInUp;
}

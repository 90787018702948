.modal {
    .modal-dialog {
        .modal-header {
            align-items: center;

            .close {
                margin: -12px 0px;
                color: $white;
            }
        }
    }
}
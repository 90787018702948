.bg-table {
  background-color: #171924;
}

.table-header-border {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-bottom: 1px solid #232531;
}

.overflow-y-unset {
  overflow-y: unset !important;
}

.overflow-unset {
  overflow: unset !important;
}

// .modal-90w {
//   width: 1320px !important;
//   // height: 720px !important;
//   max-width: none !important;
//   @media (max-width: 700px) {
//     width: 100% !important;
//   }
// }

/* styles.css */
.nav-tabs .nav-link {
  border: none;
  border-radius: 0;
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
  margin: 0;
  background-color: transparent;
  color: #aaa; /* Customize the text color */
  padding: 0.5rem 1rem;
  transition: 0.3s;
  font-weight: 700;
  :hover {
    color: #fff !important;
  }
}

.nav-tabs .nav-link.active {
  background-color: #3861fb !important; /* Customize the active tab background color */
  color: #fff !important; /* Customize the active tab text color */
}

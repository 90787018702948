.card.subscriptionCard {
  background-color: #171924 !important;
  border-radius: 10px;
  transition: transform 0.2s, box-shadow 0.2s, border 0.2s; // Smooth transition for a better effect

  // Default shadow
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: dotted 1px rgba(255, 255, 255, 0.2) !important;

  &:hover {
    border: solid 1px rgba(255, 255, 255, 0.3) !important;

    // When hovered, push card a bit down and enhance shadow for 3D effect
    transform: translateY(-6px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  .card-header {
    text-align: center;
    height: 50px;
    font-size: 20px !important;
    background-color: rgba(221, 21, 188, 0.6);
    border-radius: 10px;
  }
  .card-body {
    padding: 5%;
    font-size: 18px !important;
    .card-text {
      font-size: 18px !important;
      text-align: center;
    }
  }
  .card-footer {
    height: 90px !important;
    padding: 1.25rem 1.25rem !important;
    .btn.btn-primary {
      width: 100%;
      height: 100%;
      font-size: 18px !important;
      border-radius: 10px;
    }
  }
}

.sub-card {
  background-color: transparent;
  background-image: linear-gradient(#171924, rgba(8, 34, 53, 0));
  border-color: #4e5b65;
  border-radius: 16px;
  /* background-color: #171924; */
  border: 1px solid rgba(211, 219, 226, 0.2);
  display: flex;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  padding: 1rem 1.5rem;
  border-radius: 16px;
  border-width: 2px;
  transition: all 0.2s;
}
.sub-card:hover {
  background-image: linear-gradient(#212461, rgba(8, 34, 53, 0));
  border-color: #6475f6;
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(56, 30, 207, 0.3);
}
.sub-card-wrapper {
  padding: 1.5rem 0;
  padding-top: 3rem;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  width: 100%;
}
.card-top-wrapper {
  min-height: 6.4rem;
  margin-bottom: 1rem;
  grid-row-gap: 1rem;
  text-align: center;
  flex-direction: column;
  margin-bottom: 1.5rem;
  display: flex;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}
.margin-0 {
  margin: 0;
  font-size: 1.2rem;
}
.margin-bottom {
  margin-bottom: 1rem;
}
.margin-bottom-2 {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.margin-xxsmall {
  margin: 0.25rem;
}
.heading-style {
  color: #6475f6;
  font-size: 2.125rem;
  line-height: 1;
  font-weight: 600;
}
.price {
  text-align: center;
  font-size: 38px;
  font-weight: 700;
}
.price-month {
  font-size: 18px;
  font-weight: normal;
}

.sub-card-btn {
  color: #001321;
  text-align: center;
  background-color: #00e3b4;
  background-image: url("../../../../assets/images/bg.jpeg");
  background-position: 50% 80px;
  background-repeat: repeat-x;
  background-size: auto 100%;
  border: 1px solid #00e3b4;
  border-radius: 20px;
  padding: 1.4rem 1.2rem;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.3;
  text-decoration: none;
  transition: all 0.2s;
  cursor: pointer;
  display: inline-block;
}
.sub-card-btn:hover {
  background-position: 50% 60%;
  background-size: auto 110%;
  border-color: #edf2f6;
  outline: 0;
}
.flex-vertical {
  flex-direction: column;
  display: flex;
}
.card-desc {
  margin-top: 2.25rem;
}
.sub-option-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.check-icon {
  width: 0.9rem;
  height: 1.3rem;
  min-width: 0.9rem;
  padding-top: 0.4rem;
  margin-right: 0.5rem;
}

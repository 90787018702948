.faq-title {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 24px;
  margin-top: 48px;
}

.faq-item {
  margin-top: 24px;
  margin-bottom: 24px;
  color: #a1a7bb;
  font-size: 14px;
  line-height: 21px;
  position: relative;
}

.faq-item-header {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-inline-end: 32px;
}

.faq-item-header-title {
  text-decoration: inherit;
  margin: 0px;
  padding: 0px;
  line-height: 130%;
  display: block;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
}

.faq-item-header-icon {
  position: absolute;
  right: 0px;
  top: -2px;
}

.faq-item-body {
  margin-top: 8px;
  margin-bottom: 24px;
}

.faq-item-body.hidden {
  visibility: hidden;
  height: 0px;
  overflow: hidden;
}

.faq-item-body p {
  font-size: 14px;
}

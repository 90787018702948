.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5); /* semi-transparent white */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* ensure it's on top */
}

.spinner-border {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    vertical-align: -0.125em;
    border: 0.5em solid currentcolor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: .75s linear infinite spinner-border;
}

.category_divide {
  width: 1px;
  height: 16px;
  margin: 0px 16px 0px 16px;
  background: #323546;
}

.project_tabs_divide {
  width: 1px;
  height: 38px;
  margin: 0px 12px 0px 10px;
  background: #323546;
}

.detail-project-name {
  font-size: 32px;
  line-height: 42px;
  display: flex;
  align-items: center;
  font-weight: 700;
}

.list-project-details a {
  font-size: 16px;
  color: #6188ff;
}

.carousel-video-item {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel-video-item::before {
  content: '';
  width: 68px;
  height: 48px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 68 48"><path fill="%23f00" fill-opacity="0.8" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"></path><path d="M 45,24 27,14 27,34" fill="%23fff"></path></svg>');
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  opacity: 0.7;
  transition: all 0.3s ease 0s;
}

.carousel-video-item:hover::before {
  opacity: 1;
}

.list-project-details .icon-project-detail {
  width: 20px;
  height: 20px;
  color: #858ca2;
  /* margin: 0px 8px 0px 8px; */
}

.project-detail-description {
  color: #a1a7bb;
}

.project-detail-description h2 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
}

.project-detail-description p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
}

.project-detail-desc-title p {
  color: #a1a7bb;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0px;
}

.project-detail-desc-title h1 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
}

.inner-carousel-item {
  text-decoration: none !important;
}

.inner-carousel-item div {
  width: 280px;
  height: 156px;
}

.inner-carousel-item img {
  border-radius: 15px;
}

.inner-carousel-item p:first-of-type {
  font-size: 12px;
  line-height: 18px;
  margin: 0px;
  color: #a1a7bb;
}

.inner-carousel-item p:nth-of-type(2) {
  font-size: 14px;
  line-height: 24px;
  color: white;
}

.x-scroll-wrapper {
  overflow-x: auto;
}

.x-scroll-wrapper::-webkit-scrollbar {
  display: none;
}

/* .main-select__menu{
  z-index: 999999!important;
} */

@media screen and (min-width: 768px) {
  .content_card_title {
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
  }

  .content_card_detail {
    font-size: 14px;
    color: rgb(161, 167, 187);
    line-height: 1.5;
  }
}

@media screen and (max-width: 768px) {
  .x-scroll-wrapper-mobile {
    overflow-x: auto;
  }

  .x-scroll-wrapper-mobile::-webkit-scrollbar {
    display: none;
  }
}

.modal-100wh.modal-dialog {
  width: 100%;
  margin: 0;
  height: 100%;
  min-width: 100%;
}

.modal-100wh.modal-dialog .modal-content {
  width: 100%;
  margin: 0;
  height: 100%;
}

.modal-100wh.modal-dialog .modal-content .modal-body {
  overflow: auto;
}

@media (min-width: 576px) {
  .modal-100wh.modal-dialog {
    max-width: 100%;
    margin: 0;
    min-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .ml-mobile-auto {
    margin-left: auto;
  }
  .sm-width-100{
    width: 100%;
  }
}

.bookmark-button {
  text-align: 'center';
}

/* @media screen and (min-width: 1152px){
  .content_card_title{
    font-size: 48px;
    line-height: 66px;
  }
} */

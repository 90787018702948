/* Tables */

.table {
  margin-bottom: 0;
  background-color: #17171a;
  color: white;
  border: none;
  thead {
    th {
      border-top: 0;
      border-bottom-width: 0;
      font-family: $font-family;
      font-weight: bold;
      i{
        margin-left: 0.325rem;
      }
      span.sort-icons {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
      }
      &:hover {
        color: $white-smoke;
      }
    }
  }
  th,
  td {
    text-align: right;
    vertical-align: middle;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    cursor: pointer;
    white-space: nowrap;
    font-weight: 500;
    padding: 0.7rem;
    img {
      width: 30px;
      height: 30px;
      border-radius: 100%;
    }
    .badge {
      margin-bottom: 0;
    }
  }  
  th {
    font-size: 12px;
    font-weight: bold;
    text-transform: capitalize;
  }
  tbody tr:hover {
    color: $text-muted !important;
    background-color: #171924 !important;
  }
  &.table-borderless{
    border: none;
    tr,td,th{
      border: none;
    }
  }
}

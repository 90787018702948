.main-spinner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #eee;
  .donut {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.3rem solid rgba(#979fd0, 0.3);
    border-top-color: #979fd0;
    animation: 1.5s spin infinite linear;
  }
}

.loading {
  display          : inline-block;
  width            : 40px;
  height           : 40px;
  border           : 5px solid rgba(#f8b94b, 0.2);
  border-radius    : 50%;
  border-top-color : #f8b94b;
  animation        : spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  z-index          : 1;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.linkWrapper{
  display: flex;
  justify-content: center;
  padding: 4px 8px;
  margin: 2px 12px;
  border-radius: 8px;
  border: 1px solid #40424E;
}

.linkWrapper:hover{
  border-color: #3861FB;
}

.linkInput{
  background-color: #17171A;
  border: none;
  color: white;
}

.copyButton{
  border-radius: 8px;
}
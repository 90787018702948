.news-thumbnail-con {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 200px;
}
.news-thumbnail-con a {
  width: 100%;
}
.news-thumbnail {
  border-radius: 10px;
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 100%;
  object-fit: cover;
  transition: opacity 1s linear 0s;
  opacity: 1;
}
.news-title a {
  font-size: x-large;
  font-weight: bold;
  color: white;
  text-decoration: none;
}
.news-title a:hover {
  font-size: x-large;
  font-weight: bold;
  color: white;
  text-decoration: none;
}
.news-description {
  margin-top: 15px;
  font-size: large;
  color: #a1a7bb;
  font-weight: 500;
}
.news-project {
  margin-top: 10px;
  font-size: large;
  font-weight: 600;
}
.news-datetime {
  margin-top: 10px;
  color: #a1a7bb;
  font-size: medium;
  font-weight: 500;
}

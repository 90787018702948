.toc-left-con {
  top: 10px;
  width: 230px;
  height: 100%;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  margin-right: 90px;
}

.toc-left-con-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  padding-bottom: 10px;
}

.toc-left-con-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-right: 30px;
}

.toc-left-con-list-container {
  overflow-y: auto;
  max-height: calc(100vh - 350px);
  padding: 0 10px 0 0;
  scrollbar-color: #3761fb #ffffff4d;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
}

.toc-left-con-list-outline {
  left: 0;
  width: 2px;
  height: 100%;
  position: absolute;
  background-color: #ffffff4d;
}

.toc-left-con-list-item {
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0 10px 25px;
  color: #ffffffcc;
  opacity: 80%;
  position: relative;
  display: flex;
  align-items: center;
}

.toc-left-con-list-item a {
  color: inherit;
  text-decoration: none;
}
.toc-left-con-list-item a:hover {
  cursor: pointer;
  text-decoration: none !important;
  color: inherit !important;
}

.toc-left-con-list-item.active {
  color: #3761fb;
  opacity: 100%;
}

.active-arrow {
  width: 0;
  height: 0;
  font-size: 16px;
  position: absolute;
  margin-left: -25px !important;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #3761fb;
  z-index: 1;
}

.active-arrow-background {
  width: 30px;
  height: 100%;
  position: absolute;
  margin-left: -43px !important;
  background-color: #18161a;
  /* left: 0; */
}

.query-gradient-one {
  z-index: 0;
  width: 64rem;
  height: 64rem;
  background-image: radial-gradient(
    circle farthest-side at 100% 10%,
    rgba(133, 134, 173, 0.15),
    rgba(133, 134, 173, 0) 88%
  );
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}
.query-gradient-two {
  z-index: 0;
  width: 48rem;
  height: 48rem;
  background-image: radial-gradient(
    circle closest-corner at 0%,
    rgba(133, 134, 173, 0.15) 14%,
    transparent
  );
  position: absolute;
  top: 48rem;
  bottom: auto;
  left: 0%;
  right: auto;
}
.query-gradient-two-b {
  z-index: 0;
  width: 48rem;
  height: 48rem;
  background-image: radial-gradient(
    circle closest-corner at 0%,
    rgba(133, 134, 173, 0.15) 8%,
    transparent
  );
  position: absolute;
  top: 68rem;
  bottom: auto;
  left: 0%;
  right: auto;
}
.query-gradient-three {
  z-index: 0;
  width: 70rem;
  height: 70rem;
  background-image: radial-gradient(
    circle closest-corner at 100%,
    rgba(133, 134, 173, 0.12) 14%,
    transparent
  );
  position: absolute;
  top: 102rem;
  bottom: auto;
  left: auto;
  right: 0%;
}
.query-gradient-three-b {
  z-index: 0;
  width: 70rem;
  height: 70rem;
  background-image: radial-gradient(
    circle closest-corner at 100%,
    rgba(133, 134, 173, 0.1) 14%,
    transparent
  );
  position: absolute;
  top: 118rem;
  bottom: auto;
  left: auto;
  right: 0%;
}
.query-gradient-four {
  z-index: 0;
  width: 64rem;
  height: 64rem;
  background-image: radial-gradient(
    circle closest-corner at 16%,
    rgba(133, 134, 173, 0.1) 11%,
    transparent 92%
  );
  position: absolute;
  top: 180rem;
  bottom: auto;
  left: 0%;
  right: auto;
}
.query-gradient-four-b {
  z-index: 0;
  width: 64rem;
  height: 64rem;
  background-image: radial-gradient(
    circle closest-corner at 4%,
    rgba(133, 134, 173, 0.1) 11%,
    transparent 92%
  );
  position: absolute;
  top: 210rem;
  bottom: auto;
  left: 0%;
  right: auto;
}
.query-gradient-five {
  z-index: 0;
  width: 70rem;
  height: 70rem;
  background-image: radial-gradient(
    circle closest-corner at 88%,
    rgba(133, 134, 173, 0.1) 14%,
    transparent
  );
  position: absolute;
  top: 310rem;
  bottom: auto;
  left: auto;
  right: 0%;
}
.section-v2.is--home-hero {
  margin-bottom: 150px;
  padding-top: 144px;
}
.h1-text {
  color: #edf2f6;
  margin-top: 0;
  margin-bottom: 0;

  font-family: "Kuniforma 70", sans-serif;
  font-size: 56px;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (max-width: 768px) {
  .h1-text {
    font-size: 38px;
    margin-bottom: 0;
  }
}
.text-size-medium {
  font-size: 18px;
}
.section-v2.is--mb200 {
  margin-bottom: 200px;
}
.is--1336.is--logos {
  grid-row-gap: 40px;
  text-align: center;
  flex-direction: column;
  display: flex;
}
.outlined-logo-list-wrapper.update-version {
  grid-row-gap: 32px;
  border-style: none;
  flex-direction: column;
  margin-top: 0;
  padding: 0;
  display: flex;
}
.outlined-logo-list.update-version.homepage.slide-in-2.is--6 {
  max-width: 940px;
  grid-column-gap: 60px;
  grid-row-gap: 32px;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
}
.outlined-logo-list.update-version.homepage {
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  display: flex;
}
.width-100 {
  width: 100%;
}

/* one component */

body {
  font-family: "Kuniforma 70", sans-serif !important;
  /* background-color: #061019 !important; */
}

/* .bg-dark-nb {
  background-color: #061019;
} */

.h2-s {
  color: #edf2f6;
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Kuniforma 70", sans-serif;
  font-size: 48px;
  font-weight: 500;
  line-height: 1.2;
  text-wrap: balance;
}

.slide-in-2 {
  text-wrap: balance;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #d3dbe2;
}

.content-title {
  color: rgba(211, 219, 226, 0.7);
  font-family: "Kuniforma 70", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 30px;
}

.content-description {
  height: auto;
  color: rgba(211, 219, 226, 0.7);
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 16px;
  line-height: 185.714%;
  overflow: hidden;
  margin-bottom: 0;
}

.text-white {
  color: white;
}

.text-content {
  color: rgba(211, 219, 226, 0.7);
}

.strong {
  font-weight: 700;
}

.content-item {
  width: auto;
  max-width: none;
  grid-column-gap: 1rem;
  cursor: pointer;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 24px;
  display: flex;
}

.active-content-item {
  opacity: 1;
  /* padding-top: 5px;
  padding-bottom: 5px; */
}

.content-img-item {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* To handle images of different sizes */
}

.content-img {
  width: 100%;
  top: 4rem;
  right: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 1; /* Default state is fully visible */
}

.content-img.fixed-height {
  height: 400px;
  width: auto;
}

.hero-left {
  padding-right: 9%;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}

.stat-title {
  color: #ffffff;
  font-family: "Kuniforma 70", sans-serif;
  font-size: 32px;
  line-height: 1;
}

.stat-title.larger {
  font-size: 64px !important;
}

.stat-title.smaller {
  font-size: 2rem !important;
}

.stats-col-after::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  background-image: linear-gradient(#bd8bf3, #30459e);
}
.stats-col-before::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  background-image: linear-gradient(#bd8bf3, #30459e);
}

@media screen and (max-width: 768px) {
  .stats-col-after::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(#bd8bf3, #30459e);
    top: 100%;
  }
  .stats-col-before::before {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 100%;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(#bd8bf3, #30459e);
  }
}
.coverage_logo-overaly {
  background-image: linear-gradient(
    to right,
    #001321,
    transparent 51%,
    #001321
  );
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.coverage_logo-img {
  height: 50px;
  margin-right: 20px;
}

.logo-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

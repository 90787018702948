/* Avatar.css */

/* Customize the avatar container size */
.avatar {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 10px; /* Adjust the font size for the name */
    text-transform: uppercase; /* Optionally, convert the name to uppercase */
  }
  
.link-item-a {
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.link-item-150 {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.link-item-200 {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.link-item-300 {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.link-item-400 {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.link-item-600 {
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.link-item-500 {
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.link-item-800 {
  max-width: 800px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.link-item-1000 {
  max-width: 1000px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.link-item-null {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.all-website-logo {
  width: 36px !important;
  height: 36px !important;
}
.all-website-item-summary {
  color: white;
  font-size: 14px;
}
.all-website-item-title{
  font-size: 16px;
}
.all-website-item-summary-a:hover {
  text-decoration: none;
}

.topnews-card {
  height: 220px;
  padding: 16px;
  padding-bottom: 8px;
  box-shadow: rgba(88, 102, 126, 0.08) 0px 4px 24px,
    rgba(88, 102, 126, 0.12) 0px 1px 2px;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0px;
  color: rgb(255, 255, 255);
  background-color: #222531;
  transition: box-shadow 150ms ease-in 0s;
  margin-bottom: 12px;
  margin-top: 12px;
}

.topnews-card-title {
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
}

.card-title-icon {
  font-size: 20px;
}

.more-text {
  color: #3761fb;
  font-weight: 600;
  font-size: 12px;
}

.more-text-wrapper {
  cursor: pointer;
}

.more-text-wrapper:hover .more-text {
  color: #4e72f3;
  text-decoration: underline;
}

.topnews-card-list-item {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  height: 40px;
  cursor: pointer;
  white-space: nowrap;
  padding-left: 5px;
  gap: 10px;
  @media (max-width: 576px) {
    padding-left: 0px!important;
  }
}

.topnews-card-list-item .left {
  -webkit-box-align: center;
  align-items: center;
  display: grid;
  grid-template-columns: 8px minmax(62px, auto) minmax(40px, 1fr);
  overflow: hidden;
  min-width: 130px;
}

.topnews-card-list-item .left .rank {
  font-size: 12px;
  line-height: 18px;
  color: #858ca2;
}

.topnews-card-list-item:hover {
  color: #4e72f3 !important;
}

.topnews-card-list-item:hover .gray-text {
  color: #4e72f3 !important;
}

.gray-text {
  color: #858ca2;
}

.gray-dot {
  width: 2px;
  height: 2px;
  color: #858ca2;
  flex-shrink: 0;
  margin: 0px 6px;
  background: rgb(166, 176, 195);
  border-radius: 100%;
}

.top-community-article-description {
  font-size: 14px;
  margin-bottom: 8px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.react-multi-carousel-dot--active button {
  background-color: #3761fb !important;
}

.react-multi-carousel-dot button {
  background-color: grey;
  border-width: 0 !important;
  width: 8px !important;
  height: 8px !important;
}

.card-height-padding {
  padding-bottom: 20px;
  height: 100%;
}

.react-multi-carousel-list {
  height: 100% !important;
  align-items: start !important;
}

.partner-title {
  font-size: 12px;
  font-weight: 600;
}

.partner-subtitle {
  font-size: 12px;
}

.price-change {
  font-size: 12px;
  font-weight: 500;
  word-break: break-all;
  transform-origin: left center;
}

.project-channel-title {
  cursor: pointer;
}

.project-channel-title p {
  font-size: 14px;
  color: #a1a7bb;
  font-weight: 500;
}

.project-channel-title:hover p {
  color: #4e72f3;
}

.system-font {
  font-family: Inter, -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
    Helvetica, Arial, sans-serif;
}

.card-video-wrapper {
  margin-top: 16px;
  display: flex;
  gap: 16px;
  overflow: hidden;
  align-items: center;
}

@media (max-width: 576px) {
  .card-video-wrapper {
    margin-top: 16px;
    display: block;
    gap: 0px;
    overflow: hidden;
    align-items: center;
  }
  .project-channel-title {
    margin-top: 12px;
  }
}

.h-content {
  height: fit-content !important;
}

.sub-title-statistics {
  font-size: 12px;
  color: #a1a7bb;
  font-weight: 600;
  margin-bottom: 4px;
}

.video-detail-text {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
}

.video-detail-text.right {
  font-size: 12px;
  color: #a1a7bb;
}

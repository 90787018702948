/* Define your dark mode styles */
// .wrapper-class {
//   background-color: #333; /* Dark background color */
//   color: #fff; /* Text color */
// }

.editor-class {
  background-color: #222; /* Darker background color for the editor content */
  color: #fff; /* Text color */
}

// .toolbar-class {
//   background-color: #444; /* Toolbar background color */
//   color: #fff; /* Text color */
// }

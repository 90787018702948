$input-font-size:16px;

.login {
  display: flex;
  justify-content: center;
}

.login .container {
  max-width: 400px;
  flex: 1;
}

.login .fb {
  background-color: #3b5998;
  color: white;
  width: 200px;
  display: block;
}

.login .google {
  background-color: #dd4b39;
  color: white;
  width: 200px;
  display: block;
}

.login .logins {
  margin: 5px 0;
}

.login-signup-modal {
  padding: 8px 16px;
}

.login-signup-modal-body {
  padding: 8px 24px;
  background-color: #222531 !important;
}
.login-signup-input {
  border-radius: 8px !important;
  background-color: #171924 !important;
  border: 1px solid #40424e !important;
  font-size: $input-font-size !important;
  height: 56px !important;
  -webkit-transition: box-shadow linear 1s !important;
  transition: box-shadow linear 1s !important;
}

.login-signup-input::placeholder {
  font-size: $input-font-size !important;
}

.login-signup-input:focus {
  border: 1px solid #143b91 !important;
  box-shadow: 0 0 20px #143b91 !important;
}
.login-signup-input:hover {
  border: 1px solid #143b91 !important;
}
.modal-label-name {
  margin-bottom: 4px;
}

.login-button {
  border-radius: 10px !important;
}

.login-signup-tab {
  display: flex;
  direction: row;
  width: 100%;
  margin-left: 40px;
  justify-content: center;
  align-items: center;
}

.header-button {
  display: flex;
  justify-content: center;
  font-size: 22px;
  margin: 5px 20px;
  position: relative;
  color: #a1a7bb;
  cursor: pointer;
}

.header-button.active {
  font-weight: bold;
  color: white;
}

.header-button-underline {
  position: absolute;
  background: #6188ff;
  pointer-events: none;
  bottom: -13px;
  height: 5px;
  width: 28px;
  border-radius: 100px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}
.header-button-underline.hidden {
  display: none;
}

.login-signup-modal .modal-content {
  background-color: #222531;
  border-radius: 12px;
}

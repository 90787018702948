.top-sum-con {
  height: 100%;
  max-height: calc(100vh - 160px);
}

.top-sum-body {
  height: calc(100% - 50px);
  overflow-y: auto;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.project-detail-body-con {
  height: calc(100% - 58px);
}

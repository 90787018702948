@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "./variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "./fonts";
@import "./functions";
@import "./animations";
@import "./global";

/* === Icon fonts === */
// @import "~@mdi/font/scss/materialdesignicons";

/* === Template mixins === */
@import "./mixins/animation";
@import "./mixins/badges";
@import "./mixins/buttons";
@import "./mixins/misc";
@import "./mixins/color-functions";
@import "./mixins/cards";
@import "./mixins/blockqoute";

/* === Core Styles === */
@import "./typography";
@import "./reset";
@import "./responsive";
@import "./misc";
@import "./utilities";
@import "./spinner";

/* === Components === */

@import "./components/checkbox-radio";
@import "./components/forms";
@import "./components/icons";
@import "./components/tables";
@import "./components/tabs";
@import "./components/buttons";
@import "./components/breadcrumbs";
@import "./components/badges";
@import "./components/cards";
@import "./components/preview";
@import "./components/lists";
@import "./components/todo-list";
@import "./components/dropdowns";
@import "./components/loaders/loaders";
@import "./components/modals";

@import "./components/landing-screens/auth";

/* === Plugin Overrides === */
@import "./components/plugin-overrides/slick-carousel";
@import "./components/plugin-overrides/jquery-jvectormap";

@import "./layout/navbar.scss";
@import "./layout/sidebar.scss";
@import "./layout/layout.scss";

.social-icons-row {
  /* display: grid; */
  grid-template-columns: repeat(auto-fill, minmax(36px, 1fr));
  gap: 0 20px;
  margin: 0;
  padding: 0;
}

.website-social-link-icon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  /* margin-bottom: 12px; */
}

@media (max-width: 576px) {
  .website-social-link-icon {
    margin-bottom: 12px;
  }
}

.website-social-link-icon:hover {
  transform: translateY(-5px);
}

/* Dynamic sizing based on the container width */
.website-social-link-icon > * {
  width: 36px;
  height: auto;
  min-width: 36px;
}

.custom-margin-10 {
  margin-left: 10%;
  margin-right: 10%;
}

/* Adjust the breakpoint as needed, 768px is a common breakpoint for mobile devices */
@media (max-width: 768px) {
  .custom-margin-10 {
    margin-left: 0;
    margin-right: 0;
  }
}

.website-details-overview-summary-box {
  /* background-color: #222531; */
  background-image: linear-gradient(to right, #2f2f2f, #161616);
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
}

.website-details-overview-summary-content {
  font-style: italic;
}

.website-overview-blog {
  font-size: 16px;
}

.website-overview-blog h1 {
  font-size: 28px;
  font-weight: 700;
  margin: 0 !important;
  padding-bottom: 20px;
  word-break: break-word;
}

.website-overview-blog h2 {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 16px;
  margin: 0 !important;
  word-break: break-word;
  font-weight: bold;
}

.website-overview-blog p {
  font-size: 16px;
  font-weight: 400;
  margin: 0 !important;
  padding-bottom: 1.75rem;
  line-height: 1.75rem;
  letter-spacing: 0.04em;
  word-break: break-word;
}

.website-overview-blog h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 !important;
  padding-bottom: 1.75rem;
  line-height: 1.75rem;
  letter-spacing: 0.04em;
  word-break: break-word;
}

.website-overview-blog ul {
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 2em;
  margin-block-end: 2em;
  -webkit-padding-start: 40px;
  padding-inline-start: 40px;
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.website-overview-blog li {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.04em;
  margin-bottom: 16px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: $body-color;
  box-sizing: border-box;
  height: 100vh;
  min-height: 100%;
}

*::-webkit-scrollbar {
  width: 10px;
  // height: 10px;
  background-color: transparent;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $border-color;
  box-shadow: inset 0 0 6px $border-color;

  @media (min-width: 991px){
    background: rgb(34, 37, 49);
  }
}

*::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgb(50, 53, 70);
  border: 3px solid rgb(34, 37, 49);
  border-radius: 0.5em;
}

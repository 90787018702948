
.chart-switch-btn {
  background-color: transparent;
  font-weight: bold;
  transition: all 0.2s ease;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #546077;
}

.chart-switch-btn.selected {
  background-color: #323546;
}

.project-chart-switch-con {
  background-color: #222531;
  padding: 3px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  border: 1px solid #323546;
}

.chart-term-switch-btn {
  background-color: transparent;
  font-weight: bold;
  transition: all 0.2s ease;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #97a7bb;
}

.chart-term-switch-btn:hover {
  background-color: #171924;
}

.chart-term-switch-btn.selected {
  background-color: #171924;
}

.project-chart-term-switch-con {
  background-color: #323546;
  padding: 3px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}

.chart-filter-switch-btn {
  background-color: transparent;
  font-weight: bold;
  transition: all 0.2s ease;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 12px;
  color: #97a7bb;
}

.chart-filter-switch-btn:hover {
  background-color: #171924;
}

.chart-filter-switch-btn.selected {
  background-color: #171924;
}

.project-chart-filter-switch-con {
  background-color: #323546;
  padding: 3px;
  margin: 3px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}

.project-tab-btn {
  background-color: transparent;
  font-weight: bold;
  transition: all 0.2s ease;
  width: fit-content;
  padding: 8px 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
}

.project-tab-btn:hover {
  background-color: #20222d;
}

.project-tab-btn.selected {
  background-color: #3861fb;
}

.project-tab-btn.selected:hover {
  background-color: #486dfb;
}

.category-tab-btn {
  position: relative;
  background-color: transparent;
  border-radius: 1em;
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 0.8em 1.8em;
  user-select:none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s; /* Safari */
}

.category-tab-btn:hover {
  background-color:#323546;
}

.category-tab-btn.selected {
  background-color: #232b52;
  color: #6188FF;
}

.category-tab-btn.selected:hover {
  transition-duration: 0.1s;
}

.category-tab-btn:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 1em;
  left: 0;
  top:0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s;
  box-shadow: 0 0 5px 20px white;
}

.category-tab-btn:active:after {
  box-shadow: 0 0 0 0 white;
  position: absolute;
  border-radius: 1em;
  left: 0;
  top:0;
  opacity: 1;
  transition: 0s;
}

.category-tab-btn:active {
  top: 1px;
}

/* Define your dark mode styles */
// .wrapper-class {
//   background-color: #333; /* Dark background color */
//   color: #fff; /* Text color */
// }

.editor-class {
  background-color: #222; /* Darker background color for the editor content */
  color: #fff; /* Text color */
}

// .toolbar-class {
//   background-color: #444; /* Toolbar background color */
//   color: #fff; /* Text color */
// }


/* For mobile devices */
.blog-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .blog-container {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
}

// /* Medium devices (tablets, 768px and up) */
// @media (min-width: 768px) {
//   .blog-container {
//     max-width: 720px;
//   }
// }

// /* Large devices (desktops, 992px and up) */
// @media (min-width: 992px) {
//   .blog-container {
//     max-width: 960px;
//   }
// }

// /* Extra large devices (large desktops, 1200px and up) */
// @media (min-width: 1200px) {
//   .blog-container {
//     max-width: 1140px;
//   }
// }

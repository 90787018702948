.container {
  width: 100%;
  margin: 0 auto;
  /* border: 1px solid green; */
  min-height: calc(100% - 131px);
}

/* .page-body-wrapper{
  position: relative!important;
} */

.navbar {
  position: unset !important;
}

.navbar .navbar-nav .time-frame {
  margin-right: 1rem;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  background: #121212;
}

.navbar .dropdown .no-caret {
  text-decoration: none;
}

.navbar .dropdown .profile-pic {
  display: flex;
}

.navbar .dropdown .profile-pic .profile-name {
  padding: 0 5px;
  color: white;
}

.preview-list .preview-item {
  align-items: center !important;
}

@media (max-width: 991px) {
  .navbar .dropdown .profile-pic {
    padding-top: 10px;
    justify-content: flex-end;
    text-align: left;
  }
}

.footer-subscription {
  /* background-color: #000; */
}

.footer-subscription h2 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
}

.footer-subscription p {
  color: rgb(88, 102, 126);
  line-height: 1.5;
  font-size: 16px;
}

.footer-logo-description p {
  background-color: transparent;
  color: rgb(88, 102, 126);
  line-height: 1.5;
  font-size: 16px;
  margin-top: 30px;
}

.logo-name {
  background-color: transparent;
  color: rgb(88, 102, 126);
  line-height: 1.5;
  font-size: 26px;
  margin-left: 20px;
  font-weight: bold;
}

.footer-subscription img {
  width: inherit;
}

.footer-menubar .logo-content {
  width: 40px;
  opacity: 0.7;
}

.footer-menubar-section {
  text-align: left;
}

.footer-menubar-section .title {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  padding-bottom: 18px;
  padding-top: 5px;
}

.footer-menubar-section li {
  line-height: 35px;
}

.footer-menubar-section a {
  font-weight: 500;
  font-size: 14px;
  color: #a0a0a0;
  padding-left: 0px;
}

.footer-copyright {
  text-align: left;
}

.footer-copyright p {
  color: #a0a0a0;
  font-size: 14px;
  line-height: 24px;
}

.menu-icon {
  width: 24px;
  margin: 0px 0px !important;
}

.menu-title {
  margin-left: 6px;
}

/* CUSTOM CONTAINER WIDTH */

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1540px !important;
  }
}

.sidebar.background-dark {
  background-color: #171924 !important;
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  left: 0;
  top: 0;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  box-shadow: 0.1875rem 0.25rem 0.875rem #0000004d;
}

/* .sidebar{
  position: sticky!important;
  top: 0!important;
  padding-top: 0!important;
}

.sidebar-brand-wrapper{
  position: inherit!important;
} */

.menu-icon.font-size-15 i {
  font-size: 1.5rem !important;
}

.subscribe-input {
  position: relative;
  background-color: #171924 !important;
  border-radius: 8px !important;
  box-sizing: border-box !important;
  color: rgb(255, 255, 255) !important;
  max-width: 100% !important;
  outline: 0px !important;
  padding: 0px 16px !important;
  width: 100% !important;
  font-size: 16px !important;
  height: 56px !important;
  line-height: 24px !important;
  margin-top: 12px !important;
}

.subscribe-input-label {
  text-decoration: inherit !important;
  margin: 0px !important;
  padding: 0px !important;
  line-height: 150% !important;
  display: initial !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.bookmark-wrapper {
  border: unset !important;
  background-color: transparent;
  transition: all 0.3s ease;
}

.bookmark-wrapper:hover {
  background-color: #ffffff1a;
}

.bookmark-wrapper span {
  font-size: 12px !important;
  padding-left: 4px !important;
  color: #ffffff;
}

.full-width-max{
  max-width: 100%!important;
}

/* Custom col-2-5 class */
.col-2-5 {
  position: relative;
  width: 100%;
  padding-right: 15px; /* Bootstrap default gutter */
  padding-left: 15px;  /* Bootstrap default gutter */
  flex: 0 0 20.8333%;
  max-width: 20.8333%;
}

/* Ensuring the custom column behaves responsively */
@media (min-width: 576px) { /* Bootstrap's small breakpoint */
  .col-sm-2-5 {
    flex: 0 0 20.8333%!important;
    max-width: 20.8333%!important;
  }
}

@media (min-width: 768px) { /* Bootstrap's medium breakpoint */
  .col-md-2-5 {
    flex: 0 0 20.8333%!important;
    max-width: 20.8333%!important;
  }
}

@media (min-width: 992px) { /* Bootstrap's large breakpoint */
  .col-lg-2-5 {
    flex: 0 0 20.8333%!important;
    max-width: 20.8333%!important;
  }
}

@media (min-width: 1200px) { /* Bootstrap's extra large breakpoint */
  .col-xl-2-5 {
    flex: 0 0 20.8333%!important;
    max-width: 20.8333%!important;
  }
}

/* Add more breakpoints if you're using Bootstrap 4 or 5, as they have xl and xxl */

.category-group-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.category-group-divide {
  width: 1px;
  height: 24px;
  margin: 0px 10px 0px 0px;
  background: #323546;
}
.bg-table {
  background-color: #171924;
}

.overflow-unset {
  overflow: unset !important;
}

.overflow-y-unset {
  overflow-y: unset !important;
}

.table-header-filter {
  height: 38px;
  border-radius: 8px !important;
}

.project-detail-sticky-header {
  position: sticky !important;
  top: -1px;
  background: #1f212d;
  z-index: 1;
}

.project-center-sticky-con {
  height: 100%;
  max-height: calc(100vh - 20px);
}

.project-center-sticky-body {
  height: calc(100% - 50px);
  overflow-y: auto;
  overflow-x: hidden;
}

$item-height : 38px;

.search-item{
  height: $item-height !important;
  border-radius: 8px !important;
  width: 150px !important;
}
.search-input{
  height: $item-height !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  border-color: transparent !important;
  background-color: #323546 !important;
}
.login {
  background: transparent;
  padding: 4px 12px;
  border-radius: 6px;
  border: solid 1px #6188ff;
  color: #6188ff;
}

.login:hover {
  background: #222531;
}

.signup {
  background: #3861fb;
  padding: 4px 12px;
  border-radius: 6px;
  border: solid 1px #3861fb;
  color: white;
  margin-right: 12px;
}

.signup:hover {
  background: #486dfb;
}

.login-wrapper {
  margin: 0px 4px;
  text-decoration: unset !important;
  cursor: pointer;
}

.react-select__indicator {
  padding: 0px 8px!important;
}

.language-select__indicator {
  padding: 0px 0px!important;
}

.language-select__single-value{
  font-size: 10px;
  font-weight: normal;
}

.language-select__indicator svg{
  width: 15px;
}

.language-select__indicators{
  padding: 0px 3px 0px 0px !important;
}

.language-select__input-container{
  padding: 0 !important;
}

.react-select__single-value {
  font-size: 12px;
}

///////// Customize ////////
$dashboard-table-filter-height: 38px;
///////// Customize ////////

.CheckboxMenu .dropdown-item:hover,
.CheckboxMenu .dropdown-item:active {
    background-color: transparent;
    color: black;
}

.CheckboxMenu .form-check {
    margin: 0;
}

.checkbox-button{
  height: $dashboard-table-filter-height;
  font-size: 12px;
  border-radius: 8px !important;
  font-weight: 400;
}
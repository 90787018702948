.tabs {
    display: flex;
    list-style: none;
    margin-bottom: 0;


    .tab {

        &:not(:first-child) {
            margin-left: 10px;
        }

        .tab-link {
            display: block;
            color: #ffffff;
            padding: 8px 14px;
            font-size: 12px;
            font-weight: 600;
            border-radius: 8px;
            cursor: pointer;
            text-decoration: none;
            white-space: nowrap;

            &:hover {
                background-color: #171924;
            }

            &.active {
                background-color: #3861fb1a;
                color: #6188ff;
            }
        }
    }
}

.overflow-row {
    overflow-x: auto;
    overflow-y: visible;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }

    .row {
        flex-wrap: nowrap;

        .stretch-card {
            width: auto;
            white-space: nowrap;
        }
    }
}
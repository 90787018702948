.videoModal .modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure the modal is on top */
}

.videoModal .modal-content {
  padding: 10px;
  border-radius: 8px;
  position: relative;
  width: 75% !important;
  max-height: 90%;
  overflow: hidden;
  height: fit-content !important;
  @media (max-width: 768px) {
    width: 100% !important;
    padding: 0px !important;
  }
}

.player-container {
  position: relative;
  width: 100%;
  // padding-bottom: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal-dialog-centered {
  justify-content: center;
}

/* Define styles for the "Previous" and "Next" buttons */
.prev-next-button {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent; /* Transparent background */
  border: none; /* Remove border */
  border-radius: 100%;
  cursor: pointer; /* Add pointer cursor on hover */
  width: 100px; /* Adjust button width as needed */
  height: 100px; /* Adjust button height as needed */
  margin: 0; /* Remove margin to avoid spacing between buttons */
  padding: 0; /* Remove padding */
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease; /* Smooth background color transition */
  opacity: 0;
  &:hover {
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Semi-transparent background on hover */
    opacity: 1;
  }
  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
}

.prev-next-button.left {
  transform: translate(-180px, -50%);
  @media (max-width: 768px) {
    transform: translate(-140px, -50%);
  }
}

.prev-next-button.right {
  transform: translate(90px, -50%);
  @media (max-width: 768px) {
    transform: translate(60px, -50%);
  }
}

.control-button {
  display: flex;
  background-color: #3861FB; /* Transparent background */
  border: none; /* Remove border */
  border-radius: 6px;
  cursor: pointer; /* Add pointer cursor on hover */
  width: 100%; /* Adjust button width as needed */
  height: 30px; /* Adjust button height as needed */
  margin: 0; /* Remove margin to avoid spacing between buttons */
  padding: 0; /* Remove padding */
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease; /* Smooth background color transition */
  // opacity: 0.7;
  &:hover {
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Semi-transparent background on hover */
    opacity: 1;
  }
}
.control-button.disabled:hover {
  background-color: rgba(
    0,
    0,
    0,
    0
  ); /* Semi-transparent background on hover */
  opacity: 0.7;
}
.control-button.left {
  margin-right: 20px;
}
.control-button.right {
  margin-left: 20px;
}
.control-panel{
  margin-top: 10px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
}

.right-panel {
  display: flex;
  flex-direction: column;
  .mt-0 {
    order: 1;
  }
  .tabs-bottom {
    order: 3;
    border-bottom: unset !important;

    border-top: 1px solid #232531;
    .nav-link {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      border-bottom-left-radius: 0.5rem !important;
      border-bottom-right-radius: 0.5rem !important;
    }
  }
  .tab-content {
    order: 2;
    flex: 1;
  }
}
